import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo: imageSharp(fluid: {originalName: {eq: "Drive-Logo-Text-2x.png"}}) {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
        appstore: imageSharp(fluid: {originalName: {eq: "app_store.png"}}) {
          fluid(maxWidth: 215) {
            ...GatsbyImageSharpFluid
          }
        }
        googleplay: imageSharp(fluid: {originalName: {eq: "google_play.png"}}) {
          fluid(maxWidth: 215) {
            ...GatsbyImageSharpFluid
          }
        }
        hero: imageSharp (fluid: {
          originalName: { eq: "Driver-Hero-new.jpg" }
        }) {
          fluid(maxWidth: 1080, maxHeight: 719, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ hero, appstore, googleplay }) => (
      <SectionWrapper className={styles.container}>
        <Grid container xs={12} xl={12} item justify='space-between'>
          <Grid item xs={12} sm={12} md={12} lg={7}
            className={styles.heroImageContainerMobile}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              objectPosition='top right'
              className={styles.imageContainer}
              fluid={hero.fluid}
              alt={i18n('Car application for every driver')}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} className={styles.overviewContainer}
            direction='column'
            justify='center'>
            <Typography
              variant='h1'
              color='dark'
              weight='bold'
              className={clsx(
                styles.title,
                styles.heroText
              )}>
              {i18n('Your car\'s best friend')}
            </Typography>
            <Typography
              variant='h4'
              color='dark'
              weight='light'
              className={clsx(
                styles.subtitle,
                styles.heroText
              )}>
              {i18n('Safer driving. Convenient experience.')}
            </Typography>
            <div className={styles.heroText}>
              <a href={`https://apps.apple.com/dk/app/obi/id1274003679`}
                target="_blank"
                rel="noreferrer"
                className={styles.appStoreWrap}>
                <Img imgStyle={{ objectFit: 'none' }}
                  objectFit='none'
                  objectPosition='left center'
                  className={styles.imageAppStore}
                  fluid={appstore.fluid}
                  alt='Drive app on App store'/>
              </a>
              <a href={`https://play.google.com/store/apps/details?id=com.marvel`}
                target="_blank"
                rel="noreferrer"
                className={styles.appStoreWrap}>
                <Img imgStyle={{ objectFit: 'none' }}
                  objectFit='none'
                  objectPosition='left center'
                  className={styles.imageAppStore}
                  fluid={googleplay.fluid}
                  alt='Drive app on Google Play'/>
              </a>
              {/* <Link to={`${i18n('_url:landing-driver-signup')}`}
                className={styles.heroText}>
                <Button className={styles.button}>
                  {i18n('Sign up')}
                </Button>
              </Link> */}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}
            className={styles.heroImageContainerWide}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              objectPosition='top right'
              className={styles.imageContainer}
              fluid={hero.fluid}
              alt={i18n('Car application for every driver')}/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )
  }/>
);