import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import Typography from '../../../../../components/Typography';
import styles from './styles.module.sass';

export default ({
  img,
  final = false,
  title,
  text,
}) => (
  <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}
    className={styles.container}>
    <div className={styles.contentWrapper}>
      <div item className={styles.imageWrapper}>
        <Img fluid={img.fluid}
          className={clsx(
            styles.imageContainer,
            final && styles.finalImageContainer
          )}
          objectFit='contain'
          alt={text}/>
      </div>
      <div item className={styles.textWrapper}>
        <Typography variant={final ? 'h5' : 'subtitle2'}
          weight='bold' color='dark'
          align='left'
          className={styles.title}>
          {title}
        </Typography>
        <Typography variant='subtitle2' weight='light' color='dark'
          align='left'
          className={styles.text}>
          {text}
        </Typography>
      </div>
    </div>
  </Grid>
);