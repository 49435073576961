import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import i18n from '../../../../../../locale';
import Typography from '../../../../../../components/Typography';
import Button from '../../../../../../components/Button';
import Icon from '../Icon';
import styles from './styles.module.sass';

const $OBI = 0;
const $MOBILE_LOG = 1;
const $EXTRA_HW = 2;

export default () => {
  const [tab, setTab] = React.useState($OBI)
  return (
    <div className={styles.container}>
      <Grid container className={styles.buttonsContainer}>
        <Grid item sm={12} xs={12}>
          <Typography weight='bold' align='center' variant='h4'
            className={styles.titleCell}>
            {i18n('Logbook services')}<br/>
            {tab === $OBI ? i18n('OBI+')
            : tab === $MOBILE_LOG ? i18n('Mobile app logbook')
            : i18n('Extra hardware logbook')}
          </Typography>
        </Grid>
        <Grid item sm={4} xs={4} className={styles.buttonContainer}>
          <Button onClick={() => setTab($OBI)}
            className={clsx(styles.button,
              tab !== $OBI && styles.buttonUnselected)}>
            {i18n(' ')}
          </Button>
        </Grid>
        <Grid item sm={4} xs={4} className={styles.buttonContainer}>
          <Button onClick={() => setTab($MOBILE_LOG)}
            className={clsx(styles.button,
              tab !== $MOBILE_LOG && styles.buttonUnselected)}>
            {i18n(' ')}
          </Button>
        </Grid>
        <Grid item sm={4} xs={4} className={styles.buttonContainer}>
          <Button onClick={() => setTab($EXTRA_HW)}
            className={clsx(styles.button,
              tab !== $EXTRA_HW && styles.buttonUnselected)}>
            {i18n(' ')}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction='column'>
        <Grid item container>
          <Grid item sm={12} xs={12} className={styles.cell}>
            <Typography weight='bold' align='center' variant='h5'
              className={clsx(styles.cellContent, styles.cellContentOdd)}>
              {i18n('Trip data collection')}
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} className={styles.cell}>
            <Typography weight='bold' align='center' color='highlight' variant='h5'
              className={clsx(styles.cellContent, styles.cellContentOdd)}>
              {tab === $OBI ? i18n('Automated')
              : tab === $MOBILE_LOG ? i18n('Manual')
              : i18n('Automated')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item sm={12} xs={12} className={styles.cell}>
            <Typography weight='bold' align='center' variant='h5'
              className={styles.cellContent}>
              {i18n('Trip registration')}
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} className={styles.cell}>
            <Typography weight='bold' align='center' color='highlight' variant='h5'
              className={styles.cellContent}>
              {tab === $OBI ? i18n('Always unlimited')
              : i18n('Limited/unlimited plans')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item sm={12} xs={12} className={styles.cell}>
            <Typography weight='bold' align='center' variant='h5'
              className={clsx(styles.cellContent, styles.cellContentOdd)}>
              {i18n('Export reports')}
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} className={styles.cell}>
            <Typography weight='bold' align='center' color='highlight' variant='h5'
              className={clsx(styles.cellContent, styles.cellContentOdd)}>
              {tab === $OBI ? i18n('Always unlimited')
              : i18n('Limited/unlimited plans')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item sm={tab !== $MOBILE_LOG ? 12 : 9} xs={tab !== $MOBILE_LOG ? 12 : 9} className={styles.cell}>
            <Typography weight='bold' align='center' variant='h5'
              className={styles.cellContent}>
              {i18n('Hardware')}
            </Typography>
          </Grid>
          <Grid item sm={tab !== $MOBILE_LOG ? 12 : 3} xs={tab !== $MOBILE_LOG ? 12 : 3} className={styles.cell}>
            {tab === $OBI ? (
              <Typography weight='bold' align='center' color='highlight' variant='h5'
                className={styles.cellContent}>
                {i18n('No need or free')}
              </Typography>
            ) : tab === $MOBILE_LOG ? (
              <div className={styles.cellContent}>
                <Icon className={styles.icon}/>
              </div>
            ) : (
              <Typography weight='bold' align='center' color='highlight' variant='h5'
                className={styles.cellContent}>
                {i18n('200 kr. - 1000 kr.')}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item sm={12} xs={12} className={styles.cell}>
            <Typography weight='bold' align='center' variant='h5'
              className={clsx(styles.cellContent, styles.cellContentOdd)}>
              {i18n('Commitment')}
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} className={styles.cell}>
            <Typography weight='bold' align='center' color='highlight' variant='h5'
              className={clsx(styles.cellContent, styles.cellContentOdd)}>
              {tab === $OBI ? i18n('No binding, same price')
              : tab === $MOBILE_LOG ? i18n('Yes or higher price for freedom')
              : i18n('Yes or higher price for freedom')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item sm={9} xs={9} className={styles.cell}>
            <Typography weight='bold' align='center' variant='h5'
              className={styles.cellContent}>
              {i18n('Safety through connected car technology')}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={3} className={styles.cell}>
            {tab === $OBI ? (
              <div className={styles.cellContent}>
                <Icon className={styles.icon} check/>
              </div>
            ) : (
              <div className={styles.cellContent}>
                <Icon className={styles.icon}/>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item sm={9} xs={9} className={styles.cell}>
            <Typography weight='bold' align='center' variant='h5'
              className={clsx(styles.cellContent, styles.cellContentOdd)}>
              {i18n('Service & repair link')}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={3} className={styles.cell}>
            {tab === $OBI ? (
              <div className={clsx(styles.cellContent, styles.cellContentOdd)}>
                <Icon className={styles.icon} check/>
              </div>
            ) : (
              <div className={clsx(styles.cellContent, styles.cellContentOdd)}>
                <Icon className={styles.icon}/>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item sm={9} xs={9} className={styles.cell}>
            <Typography weight='bold' align='left' variant='h5'
              className={styles.cellContent}>
              {i18n('Added value')}:<br/>
              - {i18n('Find my car')}<br/>
              - {i18n('Stats')}<br/>
              - {i18n('Tips')}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={3} className={styles.cell}>
            {tab === $OBI ? (
              <div className={styles.cellContent}>
                <Icon className={styles.icon} check/>
              </div>
            ) : (
              <div className={styles.cellContent}>
                <Icon className={styles.icon}/>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item sm={12} xs={12} className={styles.cell}>
            <Typography weight='bold' align='center' variant='h4'
              className={clsx(styles.cellContent, styles.cellContentOdd, styles.titleCel, styles.priceCell)}>
              {i18n('Price')}
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} className={styles.cell}>
            <Typography weight='bold' align='center' variant='h4'
              className={clsx(styles.cellContent, styles.cellContentOdd, styles.titleCell, styles.priceCell)}>
              {tab === $OBI ? `59 ${i18n('kr/m')}`
              : tab === $MOBILE_LOG ? `50-80 ${i18n('kr/m')}`
              : `60 - 120 ${i18n('kr/m')}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
