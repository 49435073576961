import React from 'react';
import { Grid } from '@material-ui/core';
import { StaticQuery, graphql } from 'gatsby';
import i18n from '../../../../locale';
import Advantage from './Entry';
import Screenshot from './Screenshot';
import template from './template';
import styles from './styles.module.sass';

export default () => {  
  const [ selected, setSelected ] = React.useState(0);
  return (
    <StaticQuery
      query={graphql`
        query {
          shot0: imageSharp(fluid: {
            originalName: {eq: "Driver-Overview-1.jpg"}
          }) {
            fluid(maxWidth: 600, maxHeight: 1165, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot1: imageSharp(fluid: {
            originalName: {eq: "Driver-Overview-2.jpg"}
          }) {
            fluid(maxWidth: 600, maxHeight: 1165, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot2: imageSharp(fluid: {
            originalName: {eq: "Driver-Overview-3.jpg"}
          }) {
            fluid(maxWidth: 600, maxHeight: 1165, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot3: imageSharp(fluid: {
            originalName: {eq: "Driver-Overview-4.jpg"}
          }) {
            fluid(maxWidth: 600, maxHeight: 1165, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      `}
      render={(data) => (
        <Grid item container className={styles.container}>
          <Grid item container justify='space-between' sm={12} md={8} lg={8} xl={8}
            className={styles.tileContainer}>
            <Advantage onClick={() => setSelected(0)}
              selected={selected === 0}
              Tile={template[0]}
              title={i18n('Easy car overview')}
              text={i18n('All car information in your hands. Follow parking, fuel level, and history of service and repairs.')}/>
            <Advantage onClick={() => setSelected(1)}
              selected={selected === 1}
              Tile={template[1]}
              title={i18n('Peace of mind')}
              text={i18n('Have a safer and more reliable car. Follow changes in your car\'s condition and be advised by your preferred workshop.')}/>
            <Advantage onClick={() => setSelected(2)}
              selected={selected === 2}
              Tile={template[2]}
              title={i18n('Automatic trip logging and categorization')}
              text={i18n('Stop wasting time on keeping track of your trips. All are tracked automatically and all relevant information is available.')}/>
            <Advantage onClick={() => setSelected(3)}
              selected={selected === 3}
              Tile={template[3]}
              title={i18n('Hassle-free service and repair experience')}
              text={i18n('Convenient way to communicate with your workshop anytime you want. Always updated about important events that occur.')}/>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}
            className={styles.showcaseImageContainer}>
            {template.map((t, i) => (
              <Screenshot
                key={i}
                selected={selected === i}
                image={data[`shot${i}`]}/>
            ))}
          </Grid>
        </Grid>
      )}/>
  );
};