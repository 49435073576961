import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        appstore: imageSharp(fluid: {originalName: {eq: "app_store.png"}}) {
          fluid(maxWidth: 176) {
            ...GatsbyImageSharpFluid
          }
        }
        googleplay: imageSharp(fluid: {originalName: {eq: "google_play.png"}}) {
          fluid(maxWidth: 176) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ appstore, googleplay }) => (
      <SectionWrapper className={styles.container} id={i18n('_hash:getting-started')}>
        <Typography
          variant='h2'
          weight='bold'
          color='dark'
          align='left'
          className={styles.title}>
          {i18n('Getting started')}
        </Typography>
        <Grid container xs={12} xl={12} item justify='center' className={styles.cardsContainer}>
          <Grid item xs={12} sm={12} md={4} className={styles.cardWrapper}>
            <div className={styles.cardContainer}>
              <div className={styles.cardTopContainer}>
                <Typography
                  variant='subtitle2'
                  weight='light'
                  align='left'
                  color='dark'
                  className={styles.step}>
                  {i18n('STEP')} 1
                </Typography>
                <Typography
                  variant='h3'
                  weight='bold'
                  align='left'
                  color='dark'
                  className={styles.cardTitle}>
                  {i18n('Hent OBI+ appen.')}
                </Typography>
              </div>
              <div className={styles.cardBottomContainer}>
                <a href={`https://apps.apple.com/dk/app/obi/id1274003679`}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.appStoreWrap}>
                  <Img imgStyle={{ objectFit: 'none' }}
                    objectFit='none'
                    objectPosition='left center'
                    className={styles.imageAppStore}
                    fluid={appstore.fluid}
                    alt='Drive app on App store'/>
                </a>
                <a href={`https://play.google.com/store/apps/details?id=com.marvel`}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.appStoreWrap}>
                  <Img imgStyle={{ objectFit: 'none' }}
                    objectFit='none'
                    objectPosition='left center'
                    className={styles.imageAppStore}
                    fluid={googleplay.fluid}
                    alt='Drive app on Google Play'/>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className={styles.cardWrapper}>
            <div className={clsx(
                styles.cardContainer,
                styles.noShadow
              )}>
              <Typography
                variant='subtitle2'
                weight='light'
                align='left'
                color='dark'
                className={styles.step}>
                {i18n('STEP')} 2
              </Typography>
              <Typography
                variant='h3'
                weight='bold'
                align='left'
                color='dark'
                className={styles.cardTitle}>
                {i18n('Tilmeld dig på 1 min.')}
              </Typography>
              <Typography
                variant='subtitle2'
                color='highlight'
                align='left'>
                {i18n('Fuldfør registreringsprocessen.')}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className={styles.cardWrapper}>
            <div className={clsx(
                styles.cardContainer,
                styles.noShadow
              )}>
              <Typography
                variant='subtitle2'
                weight='light'
                align='left'
                color='dark'
                className={styles.step}>
                {i18n('STEP')} 3
              </Typography>
              <Typography
                variant='h3'
                weight='bold'
                align='left'
                color='dark'
                className={styles.cardTitle}>
                {i18n('Tilknyt din bil.')}
              </Typography>
              <Typography
                variant='subtitle2'
                color='highlight'
                align='left'
                className={styles.subtitle}>
                {i18n('Benyt appen helt gratis. Forbind din bil ved dit værksted hvis du vil.')}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </SectionWrapper>
    )
  }/>
);