import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import styles from './styles.module.sass';

const Tick = () => (
    <span className={styles.tickItem}>&#10004;</span>
);

const Cross = () => (
    <span className={styles.crossItem}>&#10006;</span>
);

export default () => (
  <StaticQuery
    query={graphql`
        query {
            paid: imageSharp (fluid: { originalName: { eq: "app_paid.png" }}) {
                fluid(maxWidth: 691, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            free: imageSharp (fluid: { originalName: { eq: "app_free.png" }}) {
                fluid(maxWidth: 691, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    `}
    render={({ paid, free }) => (
        <SectionWrapper className={styles.container}>
            <Grid item container className={styles.titleContainer}>
                <Grid item xs={6} sm={6} md={6}>
                    <Typography variant='h2' color='dark' weight='bold'
                        align='left' className={clsx(styles.pageTitle, styles.leftTitle)}>
                        {i18n('Gratis')}
                    </Typography>

                    <Img imgStyle={{ objectFit: 'contain' }}
                        objectFit='contain'
                        objectPosition='center bottom'
                        className={styles.imageContainer}
                        fluid={free.fluid}
                        alt={i18n('Gratis')}/>

                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Typography variant='h2' color='dark' weight='bold'
                        align='right' className={clsx(styles.pageTitle, styles.rightTitle)}>
                        {i18n('Connected car')}
                    </Typography>

                    <Img imgStyle={{ objectFit: 'contain' }}
                        objectFit='contain'
                        objectPosition='center bottom'
                        className={clsx(styles.imageContainer, styles.imageContainerRight)}
                        fluid={paid.fluid}
                        alt={i18n('Connected car')}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <table className={styles.featuresTable} cellSpacing={16}>
                        <tr className={styles.tableHead}>
                            <td colSpan={3}>
                                <div className={styles.positionRight}>
                                    <Typography variant='h2' color='dark' weight='bold'
                                        align='center' className={styles.priceTitle}>
                                        {i18n('75 kr.')}
                                    </Typography>
                                    <Typography variant='subtitle2' color='dark' weight='light' 
                                        align='center' className={styles.priceSubtitle}>
                                        {i18n('Måned')}
                                    </Typography>
                                </div>

                                <div className={styles.positionLeft}>
                                    <Typography variant='h2' color='dark' weight='bold'
                                        align='center' className={styles.priceTitle}>
                                        {i18n('0 kr.')}
                                    </Typography>
                                    <Typography variant='subtitle2' color='dark' weight='light' 
                                        align='center' className={styles.priceSubtitle}>
                                        {i18n('Måned')}
                                    </Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Tick/></td>
                            <td>{i18n('Påmindelser om dit køretøjs behov')}</td>
                            <td><Tick/></td>
                        </tr>

                        <tr>
                            <td><Tick/></td>
                            <td>{i18n('Tilføj dit foretrukne værksted')}</td>
                            <td><Tick/></td>
                        </tr>

                        <tr>
                            <td><Tick/></td>
                            <td>{i18n('Digital dokumentation')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Tick/></td>
                            <td>{i18n('Bekvem kommunikation via chat')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Tick/></td>
                            <td>{i18n('Modtag videoer fra værkstedet')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Cross/></td>
                            <td>{i18n('Connected car forbindelse')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Cross/></td>
                            <td>{i18n('Nem oversigt over bilen')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Cross/></td>
                            <td>{i18n('Del teknisk data med dit værksted')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Cross/></td>
                            <td>{i18n('Opdateret med vigtige begivenheder')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Cross/></td>
                            <td>{i18n('Hold styr på bilens serviceplan')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Cross/></td>
                            <td>{i18n('Automatisk kørselsregistrering')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Cross/></td>
                            <td>{i18n('Kørebog')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Cross/></td>
                            <td>{i18n('Følg med i ændringer i bilens tilstand')}</td>
                            <td><Tick/></td>
                        </tr>
                        
                        <tr>
                            <td><Cross/></td>
                            <td>{i18n('Vejvisning til din bil')}</td>
                            <td><Tick/></td>
                        </tr>
                        <tr className={styles.tableFooter}>
                            <td colSpan={3}>
                                <div className={styles.positionLeft}>
                                    <Typography variant='h2' color='dark' weight='bold'
                                        align='center' className={styles.priceBottom}>
                                        {i18n('0 kr. /måned')}
                                    </Typography>
                                </div>

                                <div className={styles.positionRight}>
                                    <Typography variant='h2' color='dark' weight='bold'
                                        align='center' className={styles.priceBottom}>
                                        {i18n('75 kr. /måned')}
                                    </Typography>
                                </div>
                            </td>
                        </tr>
                    </table>
                </Grid>
              
          </Grid>

        </SectionWrapper>
    )}/>
);