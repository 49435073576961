import React from 'react';
import i18n, { locale } from '../../../../locale';
import Title from '../../../../components/Title';
import Question from './Question';
import styles from './styles.module.sass';
import questions from '../../../../data/driver-faq.json';

export default () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return locale === 'da' ? (
    <div className={styles.container}>
      <Title variant='h2'
        align='center'
        className={styles.titleContainer}>
        {i18n('Get more answers.')} FAQ
      </Title>
      {questions.map((q, key) => (
        <Question key={key}
          expanded={expanded === key}
          onChange={handleChange(key)}
          summary={q.summary}
          descriptions={q.descriptions}/>
      ))}
    </div>
  ) : null;
}