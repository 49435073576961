import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import i18n from '../../../../../../locale';
import Typography from '../../../../../../components/Typography';
import Icon from '../Icon';
import styles from './styles.module.sass';

export default () => (
  <Grid className={styles.container} container direction='column'>
    <Grid item container>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h4'
          className={clsx(styles.cellContent, styles.titleCell)}>
          {i18n('Logbook services')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h4'
          className={clsx(styles.cellContent, styles.titleCell)}>
          {i18n('OBI+')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h4'
          className={clsx(styles.cellContent, styles.titleCell)}>
          {i18n('Mobile app logbook')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h4'
          className={clsx(styles.cellContent, styles.titleCell)}>
          {i18n('Extra hardware logbook')}
        </Typography>
      </Grid>
    </Grid>
    <Grid item container>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Trip data collection')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Automated')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Manual')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Automated')}
        </Typography>
      </Grid>
    </Grid>
    <Grid item container>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h5'
          className={styles.cellContent}>
          {i18n('Trip registration')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={styles.cellContent}>
          {i18n('Always unlimited')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={styles.cellContent}>
          {i18n('Limited/unlimited plans')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={styles.cellContent}>
          {i18n('Limited/unlimited plans')}
        </Typography>
      </Grid>
    </Grid>
    <Grid item container>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Export reports')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Always unlimited')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Limited/unlimited plans')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Limited/unlimited plans')}
        </Typography>
      </Grid>
    </Grid>
    <Grid item container>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h5'
          className={styles.cellContent}>
          {i18n('Hardware')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={styles.cellContent}>
          {i18n('No need or free')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <div className={styles.cellContent}>
          <Icon className={styles.icon}/>
        </div>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={styles.cellContent}>
          {i18n('200 kr. - 1000 kr.')}
        </Typography>
      </Grid>
    </Grid>
    <Grid item container>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Commitment')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('No binding, same price')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Yes or higher price for freedom')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' color='highlight' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Yes or higher price for freedom')}
        </Typography>
      </Grid>
    </Grid>
    <Grid item container>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h5'
          className={styles.cellContent}>
          {i18n('Safety through connected car technology')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <div className={styles.cellContent}>
          <Icon className={styles.icon} check/>
        </div>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <div className={styles.cellContent}>
          <Icon className={styles.icon}/>
        </div>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <div className={styles.cellContent}>
          <Icon className={styles.icon}/>
        </div>
      </Grid>
    </Grid>
    <Grid item container>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h5'
          className={clsx(styles.cellContent, styles.cellContentOdd)}>
          {i18n('Service & repair link')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <div className={clsx(styles.cellContent, styles.cellContentOdd)}>
          <Icon className={styles.icon} check/>
        </div>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <div className={clsx(styles.cellContent, styles.cellContentOdd)}>
          <Icon className={styles.icon}/>
        </div>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <div className={clsx(styles.cellContent, styles.cellContentOdd)}>
          <Icon className={styles.icon}/>
        </div>
      </Grid>
    </Grid>
    <Grid item container>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h5'
          className={styles.cellContent}>
          {i18n('Added value')}:<br/>
          - {i18n('Find my car')}<br/>
          - {i18n('Stats')}<br/>
          - {i18n('Tips')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <div className={styles.cellContent}>
          <Icon className={styles.icon} check/>
        </div>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <div className={styles.cellContent}>
          <Icon className={styles.icon}/>
        </div>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <div className={styles.cellContent}>
          <Icon className={styles.icon}/>
        </div>
      </Grid>
    </Grid>
    <Grid item container>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h4'
          className={clsx(styles.cellContent, styles.cellContentOdd, styles.titleCel, styles.priceCell)}>
          {i18n('Price')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h4'
          className={clsx(styles.cellContent, styles.cellContentOdd, styles.titleCell, styles.priceCell)}>
          59 {i18n('kr/m')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h4'
          className={clsx(styles.cellContent, styles.cellContentOdd, styles.titleCell, styles.priceCell)}>
          50-80 {i18n('kr/m')}
        </Typography>
      </Grid>
      <Grid item lg={3} xl={3} className={styles.cell}>
        <Typography weight='bold' align='left' variant='h4'
          className={clsx(styles.cellContent, styles.cellContentOdd, styles.titleCell, styles.priceCell)}>
          60 - 120 {i18n('kr/m')}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);
